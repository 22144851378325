import { Heading, Text, Stack } from '@chakra-ui/react';
import { ContainerOverlay } from 'components/Container/ContainerOverlay';
import { BigSuccessIcon } from 'components/Icons/BigSuccessIcon';
import { useEffect } from 'react';
import { useSuccessPage } from 'store/use-success-page';
import { useAuthorizeContext } from '../AuthorizeContext';
export const SuccessPage = () => {
  const {
    clientName,
    completeAuth,
    client
  } = useAuthorizeContext() || {};
  const {
    successPageRedirectUrl,
    setSuccessPageRedirectUrl
  } = useSuccessPage();
  useEffect(() => {
    const completeSessionRedirectUrl = `${window.location.origin}/complete-session`;
    const successPageUrl = successPageRedirectUrl ? new URL(successPageRedirectUrl) : null;
    const isCompleteSessionRedirect = successPageUrl && successPageUrl.origin + successPageUrl.pathname === completeSessionRedirectUrl;
    const completeAuthWithRedirect = () => {
      if (completeAuth && successPageRedirectUrl) {
        completeAuth?.({
          redirectTo: successPageRedirectUrl
        });
      }
      setSuccessPageRedirectUrl(null);
    };

    // Do not wait for the timer if the redirect is to the complete-session page
    if (isCompleteSessionRedirect) {
      completeAuthWithRedirect();
      return;
    }
    const timer = setTimeout(() => {
      completeAuthWithRedirect();
    }, 3_000);
    return () => clearTimeout(timer);
  }, [completeAuth, setSuccessPageRedirectUrl, successPageRedirectUrl]);
  return <ContainerOverlay isExitIntent data-sentry-element="ContainerOverlay" data-sentry-component="SuccessPage" data-sentry-source-file="SuccessPage.tsx">
      <Stack p="6" h="full" justify="space-between" data-sentry-element="Stack" data-sentry-source-file="SuccessPage.tsx">
        <Stack px="6" gap="6" py="40px" align="center" data-sentry-element="Stack" data-sentry-source-file="SuccessPage.tsx">
          <BigSuccessIcon data-sentry-element="BigSuccessIcon" data-sentry-source-file="SuccessPage.tsx" />
          <Heading fontWeight="medium" data-sentry-element="Heading" data-sentry-source-file="SuccessPage.tsx">Success!</Heading>
          <Text textAlign="center" data-sentry-element="Text" data-sentry-source-file="SuccessPage.tsx">
            Your account has been successfully linked to{' '}
            {clientName || client?.name}
          </Text>
        </Stack>
      </Stack>
    </ContainerOverlay>;
};