import { Flex } from '@chakra-ui/react';
import COLORS from 'constants/colors';
const ProgressBar = ({
  progress
}: {
  progress: number;
}) => {
  return <Flex position="absolute" left="0" right="0" width="100%" style={{
    bottom: '0px',
    padding: '24px'
  }} data-sentry-element="Flex" data-sentry-component="ProgressBar" data-sentry-source-file="ProgressBar.tsx">
      <div style={{
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden',
      backgroundColor: `${COLORS.GRAY.GRAY_600}`,
      cursor: 'progress',
      position: 'relative',
      border: 'none',
      fontSize: 'inherit',
      width: '100%',
      boxSizing: 'border-box',
      padding: '16px',
      alignItems: 'center',
      borderRadius: '8px',
      color: `${COLORS.WHITE}`
    }}>
        <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: `${progress}%`,
        height: '100%',
        backgroundColor: `${COLORS.BLACK}`
      }}></div>
        <span style={{
        zIndex: 1000
      }}>Connecting...</span>
      </div>
    </Flex>;
};
export default ProgressBar;