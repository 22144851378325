import { useRef, useEffect } from 'react';

export const useTimeoutManager = () => {
  // Ref to store multiple timeout IDs
  const timeoutLoaderRef = useRef<NodeJS.Timeout[]>([]);

  const defaultTimeout = process.env.DEFAULT_TIMEOUT
    ? Number(process.env.DEFAULT_TIMEOUT)
    : 700;

  // Function to execute delayed actions
  const executeDelayed = (
    fn: () => void,
    milliseconds: number = defaultTimeout,
  ) => {
    const timeoutId = setTimeout(() => {
      fn();
      // Remove timeout ID from ref after execution
      timeoutLoaderRef.current = timeoutLoaderRef.current.filter(
        (id) => id !== timeoutId,
      );
    }, milliseconds);

    // Push the timeout ID to the ref
    timeoutLoaderRef.current.push(timeoutId);
  };

  // Cleanup all timeouts on unmount
  useEffect(() => {
    return () => {
      timeoutLoaderRef.current.forEach((timeoutId) => clearTimeout(timeoutId));
      timeoutLoaderRef.current = []; // Reset the ref
    };
  }, []);

  return executeDelayed;
};
