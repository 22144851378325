import { Flex, Heading, Stack, Text } from '@chakra-ui/react';
import ProgressBar from 'components/Button/ProgressBar';
export const LoadingPage = ({
  children,
  heading,
  text,
  progress
}: {
  heading: string;
  text: string;
  progress: number;
  children: JSX.Element;
}) => {
  return <Stack align="center" style={{
    height: '100%'
  }} data-sentry-element="Stack" data-sentry-component="LoadingPage" data-sentry-source-file="LoadingPage.tsx">
      <Flex px="6" gap="3" direction="column" align="center" justify="center" style={{
      height: '90%'
    }} data-sentry-element="Flex" data-sentry-source-file="LoadingPage.tsx">
        {children}
        <Heading fontWeight="medium" style={{
        paddingTop: '30px'
      }} data-sentry-element="Heading" data-sentry-source-file="LoadingPage.tsx">
          {heading}
        </Heading>
        <Text px="0" gap="0" textAlign="center" style={{
        whiteSpace: 'pre-line'
      }} data-sentry-element="Text" data-sentry-source-file="LoadingPage.tsx">
          {text}
        </Text>
      </Flex>
      <ProgressBar progress={progress} data-sentry-element="ProgressBar" data-sentry-source-file="LoadingPage.tsx" />
    </Stack>;
};