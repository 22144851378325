import styled from 'styled-components';
const Container = styled.div<{
  openLink?: boolean;
}>`
  height: 100%;
  position: relative;
  width: 100%;
  padding: 56px 24px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  @media (min-width: 500px) {
    width: 400px;
  }
`;
export default Container;