import { ContainerOverlay } from 'components/Container/ContainerOverlay';
import { LoadingPage } from './LoadingPage';
import { useEffect, useRef, useState } from 'react';
import { LoadingStartIcon } from 'components/Icons/LoadingStartIcon';
import { LoadingPartialIcon } from 'components/Icons/LoadingPartialIcon';
import { LoadingFinishedIcon } from 'components/Icons/LoadingFinishedIcon';
import { useInterval } from 'hooks/use-interval';
const getRandom = (milliseconds: number) => {
  if (milliseconds <= 2000) {
    return Math.random() * 5 + 0.1; // first 2 seconds
  }
  if (milliseconds <= 30000) {
    return Math.random() * 0.5 + 0.1; // first 10 seconds
  }
  return Math.random() * 0.1 + 0.05;
};
enum ProgressIcon {
  Start,
  Partial,
  Finished,
}
const getMessage = (milliseconds: number, provider: string, clientName: string | undefined): {
  header: string;
  body: string;
  icon: ProgressIcon;
} => {
  if (milliseconds < 60000) {
    return {
      header: 'Logging into your account',
      body: `We're connecting to ${provider}.
      This process may take a few minutes.`,
      icon: ProgressIcon.Start
    };
  }
  if (milliseconds < 120000) {
    return {
      header: 'Hang tight!',
      body: `We're still trying to log into your account.
      \n`,
      icon: ProgressIcon.Partial
    };
  }
  return {
    header: 'Almost there!',
    body: `You will be back to ${clientName} soon.
    \n`,
    icon: ProgressIcon.Finished
  };
};
export const AuthorizeLoadingPage = ({
  provider,
  clientName,
  requestFinished
}: {
  provider: string;
  clientName?: string;
  requestFinished?: boolean;
}) => {
  const [progress, setProgress] = useState<number>(0);
  const [milliseconds, setMiliseconds] = useState<number>(0);
  const [message, setMessage] = useState<{
    header: string;
    body: string;
    icon: ProgressIcon;
  }>({
    header: 'Logging into your account',
    body: `We're connecting to ${provider}.
    This process may take a few minutes.`,
    icon: ProgressIcon.Start
  });
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const millisecondsRef = useRef<number>(0);
  const requestFinishedRef = useRef<boolean | undefined>(requestFinished);
  useEffect(() => {
    if (progress === 100) {
      setMiliseconds(0);
    }
  }, [progress]);
  useEffect(() => {
    millisecondsRef.current = milliseconds;
    setMessage(getMessage(milliseconds, provider, clientName));
  }, [milliseconds]);
  useEffect(() => {
    requestFinishedRef.current = requestFinished;
  }, [requestFinished]);
  useInterval(() => {
    setMiliseconds(prev => prev + 500);
    setProgress(prev => {
      if (requestFinishedRef.current) {
        return 100;
      }
      const next = prev + getRandom(millisecondsRef.current);
      if (next >= 100) {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
        return 100;
      }
      return next;
    });
  }, 500);
  return <ContainerOverlay data-sentry-element="ContainerOverlay" data-sentry-component="AuthorizeLoadingPage" data-sentry-source-file="AuthorizeLoadingPage.tsx">
      <LoadingPage heading={`${message.header}`} text={`${message.body}`} progress={progress} data-sentry-element="LoadingPage" data-sentry-source-file="AuthorizeLoadingPage.tsx">
        <>
          {message.icon === ProgressIcon.Start && <LoadingStartIcon />}
          {message.icon === ProgressIcon.Partial && <LoadingPartialIcon />}
          {message.icon === ProgressIcon.Finished && <LoadingFinishedIcon />}
        </>
      </LoadingPage>
    </ContainerOverlay>;
};