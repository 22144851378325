import _ from 'lodash';
import { productsConfig } from 'constants/products';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import { isClientWithoutEmploymentIncome } from 'constants/clients';
import styled from 'styled-components';
import styles from './Permissions.module.scss';
import LoadingSpinner from '../LoadingSpinner';
import { GoBackButton } from 'components/Button/GoBackButton';
import { AvatarGroup, FinchAvatar, LetterAvatar } from 'components/Avatar';
import { useRegisterStep } from 'services/register-step';
import { AppShellButton } from 'components/Button/AppShellButton';
import { AppHeading } from 'components/Text/AppHeading';
import { Stack } from '@chakra-ui/react';
import type { Category } from '@finch-api/common/dist/internal/connect/authorize';
import type { Response as ClientResponse } from '@finch-api/common/dist/internal/connect/validate';
const Container = styled.div`
  position: relative;
  padding: 42px 24px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
`;
const productNameMap: Record<string, string> = {
  company: 'Company',
  directory: 'Directory',
  individual: 'Individual',
  employment: 'Employment',
  payment: 'Payment',
  pay_statement: 'Pay Statement',
  deduction: 'Deduction',
  ssn: 'SSN'
};
const joinWithAnd = (parts: string[]): string => {
  if (parts.length <= 1) return parts.join('');
  if (parts.length === 2) return parts.join(' and ');
  return `${parts.slice(0, -1).join(', ')}, and ${parts[parts.length - 1]}`;
};
const getPermissionText = (client: ClientResponse, category: Category, product: string, config: ClientResponse['settings']['fieldFilters'] | undefined) => {
  if (product === 'company' && config && config.company) {
    const permissionParts = [];
    if (!config.company.legalName || !config.company.entity || !config.company.ein || !config.company.departments) {
      permissionParts.push('basic company data');
    }
    if (!config.company.primaryEmail || !config.company.primaryPhoneNumber) {
      permissionParts.push('company contact info');
    }
    if (!config.company.locations) {
      permissionParts.push('address details');
    }
    if (!config.company.accounts) {
      permissionParts.push('bank account data');
    }
    if (permissionParts.length === 0) {
      return productsConfig[category][product].permissionText;
    }
    return `Read ${joinWithAnd(permissionParts)}`;
  }
  if (product === 'directory' && config && config.directory) {
    const permissionParts = [];
    if (!config.directory.firstName || !config.directory.middleName || !config.directory.lastName || !config.directory.isActive) {
      permissionParts.push('company directory');
    }
    if (!config.directory.manager || !config.directory.department) {
      permissionParts.push('organization structure');
    }
    if (permissionParts.length === 0) {
      return productsConfig[category][product].permissionText;
    }
    return `Read ${joinWithAnd(permissionParts)}`;
  }
  if (product === 'individual' && config && config.individual) {
    const permissionParts = [];
    if (!config.individual.firstName || !config.individual.middleName || !config.individual.lastName || !config.individual.preferredName || !config.individual.gender || !config.individual.ethnicity || !config.individual.dob) {
      permissionParts.push('individual data');
    }
    if (!config.individual.emails || !config.individual.phoneNumbers) {
      permissionParts.push('contact info');
    }
    if (!config.individual.residence) {
      permissionParts.push('address details');
    }
    if (permissionParts.length === 0) {
      return productsConfig[category][product].permissionText;
    }
    return `Read ${joinWithAnd(permissionParts)}`;
  }
  if (product === 'employment' && config && config.employment) {
    if (isClientWithoutEmploymentIncome(client.id)) {
      return productsConfig[category].employment_without_income.permissionText;
    }
    const hasIncome = !config.employment.income || !config.employment.incomeHistory;
    const hasEmployment = Object.keys(config.employment).filter((key): key is keyof typeof config.employment => key !== 'income' && key !== 'incomeHistory').some(key => !config.employment[key]);
    if (hasIncome && hasEmployment) {
      return 'Read individual employment and income data';
    } else if (hasIncome) {
      return 'Read individual income data';
    } else if (hasEmployment) {
      return 'Read individual employment data';
    }
  }
  return productsConfig[category][product].permissionText;
};
const PermissionList = ({
  client,
  nextStep,
  products,
  category,
  prevStep,
  idpRedirect
}: AuthorizeContextValue) => {
  const registerStep = useRegisterStep();
  if (idpRedirect) {
    nextStep();
    return <></>;
  }
  if (!client) return <Container>
        <LoadingSpinner />
      </Container>;
  if (!products) throw new Error('no products');
  const onContinue = () => {
    registerStep();
    nextStep();
  };
  return <div className={styles.container} data-sentry-component="PermissionList" data-sentry-source-file="PermissionList.tsx">
      <Stack data-sentry-element="Stack" data-sentry-source-file="PermissionList.tsx">
        <div className={styles.header}>
          <GoBackButton onClick={prevStep} data-sentry-element="GoBackButton" data-sentry-source-file="PermissionList.tsx" />
          <AvatarGroup data-sentry-element="AvatarGroup" data-sentry-source-file="PermissionList.tsx">
            <LetterAvatar letter={client.name[0]} data-sentry-element="LetterAvatar" data-sentry-source-file="PermissionList.tsx" />
            <FinchAvatar data-sentry-element="FinchAvatar" data-sentry-source-file="PermissionList.tsx" />
          </AvatarGroup>
        </div>
        <Stack gap="6" data-sentry-element="Stack" data-sentry-source-file="PermissionList.tsx">
          <AppHeading data-sentry-element="AppHeading" data-sentry-source-file="PermissionList.tsx">
            {client.name} requests access to the following permissions:
          </AppHeading>
          <Stack gap="4" maxH="460px" overflow="auto" data-sentry-element="Stack" data-sentry-source-file="PermissionList.tsx">
            {products.map(product => <div data-testid="permission" className={styles.permissionsBox} key={product}>
                <div className={styles.permissionsHeader}>
                  {productNameMap[product] ? productNameMap[product] : _.startCase(_.camelCase(product))}
                </div>
                <div className={styles.permissionsSubheader}>
                  {getPermissionText(client, category, product, client.settings.fieldFilters)}
                </div>
              </div>)}
          </Stack>
        </Stack>
      </Stack>
      <AppShellButton type="submit" onClick={onContinue} data-sentry-element="AppShellButton" data-sentry-source-file="PermissionList.tsx">
        Continue
      </AppShellButton>
    </div>;
};
export default withAuthorizeContext(PermissionList);