import { useState } from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import hexOpacity from 'hex-opacity';
import { ProviderConfig, Response as ClientResponse } from '@finch-api/common/dist/internal/connect/validate';
import MainContainer from 'components/Container/MainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import FONTS from 'constants/fonts';
import COLORS from 'constants/colors';
import checkmarkIcon from 'assets/icons/checkmark.svg';
const ImplementationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;
const AuthorizationWrapper = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  padding: 24px 0px;
  ${props => props.disabled && 'opacity: 0.5;'}

  :first-child {
    border-bottom: 1px solid ${COLORS.GRAY.GRAY_400};
  }
`;
const AuthorizationStep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const AuthorizationStepLabel = styled.div`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${COLORS.GRAY.GRAY_900};
  margin-top: 4px;
`;
const AuthorizationStepInstruction = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${COLORS.GRAY.GRAY_600};
`;
const Badge = styled.span<{
  color: string;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 16px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  flex-shrink: 0;
  font-size: ${FONTS.H5};
  background-color: ${props => hexOpacity.create(props.color, 0.2)};

  &::before {
    font-weight: 700;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
  }
`;
const FirstBadge = styled(Badge)`
  &::before {
    content: '1';
  }

  ${({
  disabled
}) => disabled && css`
      background-color: #027a48;

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        background-image: url(${checkmarkIcon});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    `}
`;
const SecondBadge = styled(Badge)`
  &::before {
    content: '2';
  }

  ${({
  disabled
}) => disabled && css`
      background-color: transparent;
      border: 1px solid ${COLORS.GRAY.GRAY_400};
    `}
`;
const automatedProductList = new Set(['company', 'directory', 'individual', 'employment', 'payment', 'pay_statement']);
const SignInGuide = ({
  client,
  provider,
  products,
  currentStep,
  enabled,
  children
}: {
  client: ClientResponse;
  provider: ProviderConfig;
  products: string[];
  currentStep: string;
  enabled: boolean;
  children: React.ReactNode;
}) => {
  const [isContinued, setIsContinued] = useState(false);
  const badgeColor = COLORS.FINCH.LIGHT_GRAY;
  if (!enabled) return <>{children}</>;
  const automatedProducts = products.filter(product => automatedProductList.has(product));
  const automatedProductsNodes = automatedProducts.map((product, index) => <span key={product}>
      {index === automatedProducts.length - 1 && automatedProducts.length > 1 ? 'and ' : ''}
      {_.startCase(product)}
      {index === automatedProducts.length - 1 ? '' : ','}{' '}
    </span>);
  if (isContinued) return <>{children}</>;
  return <ImplementationWrapper data-sentry-element="ImplementationWrapper" data-sentry-component="SignInGuide" data-sentry-source-file="SignInGuide.tsx">
      <MainContainer data-sentry-element="MainContainer" data-sentry-source-file="SignInGuide.tsx">
        <AuthorizationWrapper disabled={currentStep !== 'SIGN_IN'} data-sentry-element="AuthorizationWrapper" data-sentry-source-file="SignInGuide.tsx">
          <FirstBadge color={badgeColor} disabled={currentStep !== 'SIGN_IN'} data-sentry-element="FirstBadge" data-sentry-source-file="SignInGuide.tsx" />
          <AuthorizationStep data-sentry-element="AuthorizationStep" data-sentry-source-file="SignInGuide.tsx">
            <AuthorizationStepLabel data-sentry-element="AuthorizationStepLabel" data-sentry-source-file="SignInGuide.tsx">
              Authorization, Part 1
            </AuthorizationStepLabel>
            <AuthorizationStepInstruction data-sentry-element="AuthorizationStepInstruction" data-sentry-source-file="SignInGuide.tsx">
              Authorize {client.name} to access {automatedProductsNodes}{' '}
              permissions.
            </AuthorizationStepInstruction>
          </AuthorizationStep>
        </AuthorizationWrapper>
        <AuthorizationWrapper disabled={currentStep !== 'ASSISTED_BENEFITS'} data-sentry-element="AuthorizationWrapper" data-sentry-source-file="SignInGuide.tsx">
          <SecondBadge color={badgeColor} disabled={currentStep !== 'ASSISTED_BENEFITS'} data-sentry-element="SecondBadge" data-sentry-source-file="SignInGuide.tsx" />
          <AuthorizationStep data-sentry-element="AuthorizationStep" data-sentry-source-file="SignInGuide.tsx">
            <AuthorizationStepLabel data-sentry-element="AuthorizationStepLabel" data-sentry-source-file="SignInGuide.tsx">
              Authorization, Part 2
            </AuthorizationStepLabel>
            <AuthorizationStepInstruction data-sentry-element="AuthorizationStepInstruction" data-sentry-source-file="SignInGuide.tsx">
              Authorize {client.name} to access Benefits permissions.
            </AuthorizationStepInstruction>
          </AuthorizationStep>
        </AuthorizationWrapper>
      </MainContainer>
      <LoaderButton text="Continue" onClick={() => {
      setIsContinued(true);
    }} data-sentry-element="LoaderButton" data-sentry-source-file="SignInGuide.tsx" />
    </ImplementationWrapper>;
};
export default SignInGuide;